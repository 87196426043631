<template>
  <nav>
    <router-link to="/" v-slot="{ isActive }">
      <div :class="[isActive && 'active']">
        Query secret
      </div>
    </router-link>
    <router-link to="/new" v-slot="{ isActive }">
      <div :class="[isActive && 'active']">
        Save new secret
      </div>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style>
nav {
  background-color: black;
}

a, a:visited {
  font-size: 0.85em;
  text-decoration: none;
  display: inline-block;
  color: white;
  margin: 0 10px;
}

a > div {
   padding: 5px;
}

a:hover > div {
  border-bottom: 2px solid white;
}

a > div.active {
  border-bottom: 2px solid yellow;
  color: yellow
}
</style>
