import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'ReadSecret',
    component: () => import('../views/ReadSecret.vue')
  },
  {
    path: '/new',
    name: 'NewSecret',
    component: () => import('../views/NewSecret.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
