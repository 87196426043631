<template>
  <h1>Secret Server</h1>
  <nav-bar />
  <router-view />
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  components: { NavBar }
}
</script>

<style>
* {
  font-family: 'Press Start 2P', Helvetica, Arial, sans-serif;
  margin: 0; padding: 0; border: none;
  box-sizing: border-box;
}

body {
  background-color: rgb(3, 37, 3);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #04ff64;
}

h1 {
  margin: 50px auto 20px auto;
  font-size: 2.5em;
  text-shadow: 0.12em 0 0 rgba(0, 0, 0, 0.75);
}

form {
  min-width: 320px;
  width: 50%;
  margin: 20px auto;
}

button {
  padding: 10px;
  background-color: #04ff64;
  letter-spacing: 1px;
}

button:hover {
  background-color: yellow;
}
</style>
